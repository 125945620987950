import http from "../https";

type WithdrawalBalanceAsyncProps = {
  amount: number;
  studentId: number;
  description: string;
};

export const withdrawalBalanceAsync = (data: WithdrawalBalanceAsyncProps) =>
  http.post("/api/balances-withdraws", data);
