import http from "../https";

type GetWithdrawalListParams = {
  limit?: number;
  page?: number;
  studentId: number;
};

export const getWithdrawalListAsync = (params: GetWithdrawalListParams) =>
  http.get("/api/balances-withdraws", {}, { params });
